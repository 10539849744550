var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('load-profile'),_c('b-skeleton-wrapper',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',[_c('b-skeleton',{staticClass:"mb-2",attrs:{"width":((_vm.randomNumber()) + "%")}}),_c('b-skeleton-table',{attrs:{"hide-header":"","columns":6,"rows":3,"borderless":"","striped":""}})],1)]},proxy:true}])},[(_vm.data.id)?_c('b-card',[_c('b-row',{staticClass:"mb-1",attrs:{"align-v":"start"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.data.bidDate,"DD-MMMM-YYYY"))+" ")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-none d-md-block"},[_c('b-table-simple',{staticClass:"table-information mb-0",attrs:{"responsive":"","borderless":"","striped":""}},[_c('b-tbody',[_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.startTime"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.data.startDate,"LT"))+" ")]),_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.endTime"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.data.endDate,"LT"))+" ")]),_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.status"))+": ")]),_c('b-td',[_c('span',{class:{
                        'text-warning': _vm.data.status == 0,
                        'text-primary': _vm.data.status == 1 || _vm.data.status == 2,
                        'text-success': _vm.data.status == 3,
                        'text-danger': _vm.data.status == 4,
                      }},[_vm._v(" "+_vm._s(_vm.$t(("schedule." + (_vm.data.status))))+" ")])])],1),_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.vehicleQuantity"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.vehicleCount)+" ")]),_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.totalDealer"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.bidderCount)+" ")]),_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.totalBid"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.bidCount)+" ")])],1),(_vm.data.status != _vm.scheduleState.RUNNING)?_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.vehicleBidded"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.vehicleBidCount)+" ")]),_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.rejected"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.rejectBidCount)+" ")]),_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.accepted"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.acceptBidCount)+" ")])],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"d-md-none"},[_c('b-table-simple',{staticClass:"table-information mb-0",attrs:{"responsive":"","borderless":"","striped":""}},[_c('b-tbody',[_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.startTime"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.data.startDate,"LT"))+" ")])],1),_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.endTime"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.data.endDate,"LT"))+" ")])],1),_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.status"))+": ")]),_c('b-td',[_c('span',{class:{
                        'text-warning': _vm.data.status == 0,
                        'text-primary': _vm.data.status == 1,
                        'text-primary': _vm.data.status == 2,
                        'text-success': _vm.data.status == 3,
                        'text-danger': _vm.data.status == 4,
                      }},[_vm._v(" "+_vm._s(_vm.$t(("schedule." + (_vm.data.status))))+" ")])])],1),_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.vehicleQuantity"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.vehicleCount)+" ")])],1),_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.totalDealer"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.bidderCount)+" ")])],1),_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.totalBid"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.bidCount)+" ")])],1),(_vm.data.status != _vm.scheduleState.RUNNING)?_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.vehicleBidded"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.vehicleBidCount)+" ")])],1):_vm._e(),(_vm.data.status != _vm.scheduleState.RUNNING)?_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.rejected"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.rejectBidCount)+" ")])],1):_vm._e(),(_vm.data.status != _vm.scheduleState.RUNNING)?_c('b-tr',[_c('b-th',[_vm._v(" "+_vm._s(_vm.$t("field.accepted"))+": ")]),_c('b-td',[_vm._v(" "+_vm._s(_vm.data.acceptBidCount)+" ")])],1):_vm._e()],1)],1)],1)])],1)],1):_vm._e(),(_vm.data.id)?_c('view-vehicle-table',{attrs:{"schedule":_vm.data}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }