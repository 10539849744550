<template>
  <b-modal
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    no-close-on-backdrop
    hide-footer
    size="lg"
    :title="$t('field.dealerList')"
    v-model="isShow"
  >
    <b-row>
      <b-col
        cols="auto"
        v-for="(menu, idx) in menues"
        :key="`menu-${idx}`"
        class="mb-1"
        :class="{
          'pl-sm-0': idx > 0,
        }"
        @click="changeTab(menu.id)"
      >
        <b-button
          :variant="active == menu.id ? 'primary' : 'outline-secondary'"
        >
          {{ $t(menu.label) }}
        </b-button>
      </b-col>
    </b-row>
    <n-table
      ref="table"
      :key="`table-${key}`"
      :fields="fields"
      :items="items"
      :busy="loading"
      :current-page="query.page"
      :per-page="query.limit"
      :total="total"
      :resource="resource"
      :route="route"
    >
      <template #cell(bidAt)="data">
        {{ data.item.bidAt | formatDate("h:mm:ss A") }}
      </template>
      <template #cell(increaseAmount)="data">
        {{ data.item.increaseAmount ? data.item.increaseAmount : 0 | currency }}
      </template>
      <template #cell(amount)="data">
        {{ data.item.amount | currency }}
      </template>
      <template #cell(dealer)="data">
        <span v-if="data.item.dealer">
          {{ data.item.dealer.name }}
        </span>
        <span v-else>-</span>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover
            :title="$t('button.assignDealer')"
            variant="primary"
            pill
            size="sm"
            @click="chooseBidDealer(data.item)"
          >
            <feather-icon icon="UserPlusIcon" />
          </b-button>
        </div>
      </template>
      <template #cell(action)="data">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover
            :title="$t('button.assignDealer')"
            variant="primary"
            pill
            size="sm"
            @click="chooseDealer(data.item)"
          >
            <feather-icon icon="UserPlusIcon" />
          </b-button>
        </div>
      </template>
    </n-table>
    <n-pagination
      ref="pagination"
      :total="total"
      :per-page="query.limit"
      :page="query.page"
      @change="list"
    ></n-pagination>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BButton } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import DealerTableFields from "./dealerTableFields";
import NPagination from "@/components/NPagination";
import { getTextClassByStatus } from "@/libs/helper";

const AuctionScheduleRepository = Repository.get("auctionSchedule");
const DealerRepository = Repository.get("dealer");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,

    NTable,
    NPagination,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      isShow: false,
      loading: false,
      bidderIds: [],
      items: [],
      total: 0,
      query: {
        page: 1,
        limit: 10,
      },
      id: null,
      active: 1,
      menues: [
        {
          id: 1,
          label: "field.bidDealer",
        },
        {
          id: 2,
          label: "field.dealerList",
        },
      ],
      fields: [],
      getTextClassByStatus,
    };
  },
  methods: {
    chooseBidDealer(item) {
      this.$emit("change", {
        ...item.dealer,
        amount: item.amount,
      });
      this.hide();
    },
    chooseDealer(item) {
      this.$emit("change", {
        ...item,
        amount: "",
      });
      this.hide();
    },
    changeTab(id) {
      this.active = id;
      this.items = [];
      this.total = 0;
      this.query.page = 1;
      if (this.$refs.pagination) {
        this.$refs.pagination.reset();
      }
      if (id == 1) {
        this.fields = [...TableFields];
        this.getData();
      } else {
        this.fields = [...DealerTableFields];
        if (this.bidderIds.length) {
          this.getDealer();
        } else {
          this.getBidder();
        }
      }
      this.key++;
    },
    show(item) {
      this.id = item.id;
      this.bidderIds = [];
      this.changeTab(1);
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    list(page = 1) {
      this.query.page = page;
      this.getData();
    },
    getData() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const params = {
        ...this.query,
        searchFields: [],
        query: [
          {
            searchby: "sch_vehicle_id",
            searchoperator: "=",
            search: this.id,
          },
        ],
      };
      AuctionScheduleRepository.listBidTransaction(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getBidder() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      AuctionScheduleRepository.listBidder({
        schVehicleId: this.id,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.bidderIds = [...data];
            this.getDealer();
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDealer() {
      if (!this.bidderIds.length) {
        return;
      }

      this.loading = true;
      DealerRepository.options({
        ...this.query,
        search: "",
        searchFields: [],
        query: [
          {
            searchby: "id",
            searchoperator: "notin",
            search: this.bidderIds,
          },
        ],
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const resource = "auction-schedule";
    const route = "auction-schedule";

    return {
      resource,
      route,
    };
  },
};
</script>