export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px',
    hideToggle: true,
    skipExport: true,
  },
  {
    key: 'bidAt',
    label: 'field.time',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'dealer',
    label: 'field.dealerName',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'increaseAmount',
    label: 'field.amountBid',
  },
  {
    key: 'amount',
    label: 'field.totalPriceBid',
  },
];
