<template>
  <div>
    <b-card no-body>
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="12" md="auto" class="text-right mt-1 mt-md-0">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <b-button variant="success" @click="exportExcel">
                  {{ $t("button.export") }}
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <n-table
        :key="`table-${key}`"
        :fields="fields"
        :items="items"
        :busy="loading"
        :current-page="query.page"
        :per-page="query.limit"
        :total="total"
        :route="route"
        primary-field="number"
      >
        <template #cell(vinNumber)="data">
          <span v-if="data.item.vinNumber">
            {{ data.item.vinNumber }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(dealer)="data">
          <span v-if="data.item.dealer">
            {{ data.item.dealer.name }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(bdPic)="data">
          <span v-if="data.item.bdPic">
            {{ data.item.bdPic.name }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(reservePrice)="data">
          <span v-if="data.item.reservePrice">
            {{ data.item.reservePrice | currency }}
          </span>
          <span v-else>0</span>
        </template>
        <template #cell(buyNowPrice)="data">
          <span v-if="data.item.buyNowPrice">
            {{ data.item.buyNowPrice | currency }}
          </span>
          <span v-else>0</span>
        </template>
        <template #cell(bidPrice)="data">
          <span v-if="data.item.bidPrice">
            {{ data.item.bidPrice | currency }}
          </span>
          <span v-else>0</span>
        </template>
        <template #cell(finalPrice)="data">
          <span v-if="data.item.finalPrice">
            {{ data.item.finalPrice | currency }}
          </span>
          <span v-else>0</span>
        </template>
        <template #cell(totalBid)="data">
          <b-link
            class="link-underline"
            v-if="data.item.totalBid"
            @click="openBidTransaction(data.item)"
          >
            {{ data.item.totalBid }}
          </b-link>
          <span v-else>0</span>
        </template>
        <template #cell(status)="data">
          <span
            :class="{
              'text-success': data.item.status == 6 || data.item.status == 9,
              'text-danger': data.item.status == 7,
              'text-warning': data.item.status == 8,
            }"
          >
            {{ $t(`vehicleAuctionStatus.${data.item.status}`) }}
          </span>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button
              v-b-tooltip.hover
              :title="$t('button.accept')"
              :variant="
                !$can('update', resource) ||
                data.item.status != 3 ||
                data.item.totalBid == 0
                  ? 'secondary'
                  : 'success'
              "
              pill
              size="sm"
              @click="accept(data.item)"
              :disabled="
                !$can('update', resource) ||
                data.item.status != 3 ||
                data.item.totalBid == 0
              "
              v-if="schedule.status != scheduleState.RUNNING"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover
              :title="$t('button.reject')"
              :variant="
                !$can('update', resource) ||
                data.item.status != 3 ||
                data.item.totalBid == 0
                  ? 'secondary'
                  : 'danger'
              "
              pill
              size="sm"
              @click="reject(data.item)"
              :disabled="
                !$can('update', resource) ||
                data.item.status != 3 ||
                data.item.totalBid == 0
              "
              v-if="schedule.status != scheduleState.RUNNING"
            >
              <feather-icon icon="XIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover
              :title="$t('button.reassign')"
              :variant="
                !$can('update', resource) ||
                data.item.status != 3 ||
                data.item.totalBid == 0
                  ? 'secondary'
                  : 'warning'
              "
              pill
              size="sm"
              @click="reassign(data.item)"
              :disabled="
                !$can('update', resource) ||
                data.item.status != 3 ||
                data.item.totalBid == 0
              "
              v-if="schedule.status != scheduleState.RUNNING"
            >
              <feather-icon icon="RefreshCcwIcon" />
            </b-button>
            <b-button
              v-b-tooltip.hover
              :title="$t('button.remove')"
              :variant="data.item.status != 1 ? 'secondary' : 'danger'"
              pill
              size="sm"
              @click="removeVehicle(data.item)"
              :disabled="data.item.status != 1"
              v-if="schedule.status == scheduleState.RUNNING"
            >
              <i class="lar la-calendar-minus"></i>
            </b-button>
            <b-button
              v-b-tooltip.hover
              :title="$t('button.remark')"
              variant="primary"
              pill
              size="sm"
              @click="openRemark(data.item)"
            >
              <feather-icon icon="MessageCircleIcon" />
            </b-button>
          </div>
        </template>
      </n-table>
      <n-pagination
        ref="pagination"
        :total="total"
        :per-page="$store.state.pagination.perPage"
        :page="query.page"
        @change="list"
      ></n-pagination>
    </b-card>
    <remark-modal ref="remarkModal" />
    <accept-modal @success="getData" ref="acceptModal" />
    <reject-modal @success="getData" ref="rejectModal" />
    <reassign-modal @success="getData" ref="reassignModal" />
    <remove-vehicle-modal @success="getData" ref="removeVehicleModal" />
    <bid-transaction-modal @success="getData" ref="bidTransactionModal" />
  </div>
</template>

<script>
import { BRow, BCol, BCard, BButton, BLink } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NTable from "@/components/NTable";
import NPagination from "@/components/NPagination";
import { mapExportField } from "@/libs/helper";
import RemarkModal from "../remark/RemarkModal.vue";
import AfterVehicleTableFields from "./afterVehicleTableFields";
import PreVehicleTableFields from "./preVehicleTableFields";
import moment from "moment";
import AcceptModal from "../acceptModal/AcceptModal.vue";
import RejectModal from "../rejectModal/RejectModal.vue";
import ReassignModal from "../reassignModal/ReassignModal.vue";
import RemoveVehicleModal from "../removeVehicleModal/RemoveVehicleModal.vue";
import BidTransactionModal from "../bidTransaction/BidTransactionModal.vue";
import FileSaver from "file-saver";

const AuctionScheduleRepository = Repository.get("auctionSchedule");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BLink,

    vSelect,

    NTable,
    NPagination,
    RemarkModal,
    AcceptModal,
    RejectModal,
    ReassignModal,
    RemoveVehicleModal,
    BidTransactionModal,
  },
  props: {
    schedule: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    fields() {
      let fields = [...AfterVehicleTableFields];
      if (this.schedule.status == this.scheduleState.RUNNING) {
        fields = [...PreVehicleTableFields];
      }
      return fields;
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      loading: false,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      items: [],
      total: null,
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || "seq",
        sort: this.$route.query.sort || "ASC",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    openBidTransaction(item) {
      this.$refs.bidTransactionModal.show(item);
    },
    openRemark(item) {
      this.$refs.remarkModal.show(item);
    },
    list(page = 1) {
      this.query.page = page;
      this.getData();
    },
    exportExcel() {
      this.loading = true;
      let visibleColumns = [];
      this.fields.forEach((element) => {
        if (!element.hideToggle) {
          visibleColumns.push(element.key);
        }
      });
      AuctionScheduleRepository.exportExcelVehicle({
        ...this.query,
        query: [
          {
            searchby: "sch_id",
            searchoperator: "=",
            search: this.$route.params.id,
          },
        ],
        searchFields: this.searchFields,
        fields: mapExportField(this.fields, visibleColumns, true),
      })
        .then((response) => {
          this.loading = false;
          const fileName = `Auction Schedule - ${moment().format(
            "DD-MMM-YYYY"
          )}.xlsx`;
          FileSaver.saveAs(response.data, fileName);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        query: [
          {
            searchby: "sch_id",
            searchoperator: "=",
            search: this.$route.params.id,
          },
        ],
      };
      AuctionScheduleRepository.listVehicle(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    accept(item) {
      this.$refs.acceptModal.show(item);
    },
    reject(item) {
      this.$refs.rejectModal.show(item);
    },
    reassign(item) {
      this.$refs.reassignModal.show(item);
    },
    removeVehicle(item) {
      this.$refs.removeVehicleModal.show(item);
    },
  },
  setup() {
    const resource = "auction-schedule";
    const route = "auction-schedule";

    return { resource, route };
  },
};
</script>