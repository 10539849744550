<template>
  <div>
    <b-modal
      cancel-variant="outline-secondary"
      :cancel-title="$t('button.close')"
      centered
      no-close-on-backdrop
      hide-footer
      size="lg"
      v-model="isShow"
    >
      <h5>
        {{ $t("alert.sureToAccept") }}
      </h5>
      <validation-observer ref="updateForm" #default="{ invalid }">
        <n-form-confirmation
          ref="confirmForm"
          key="updateForm"
          :form="$refs.updateForm"
          ok-variant="success"
          :disabled="invalid"
          @submit="save"
        >
          <n-input :key="`input-${key}`" :fields="formInput" v-model="data">
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="button"
                variant="outline-secondary"
                :loading="loading"
                class="mr-1"
                @submit="hide"
              >
                {{ $t("button.no") }}
              </n-button-loading>
              <n-button-loading
                type="button"
                variant="success"
                :loading="loading"
                @submit="submit"
              >
                {{ $t("button.accept") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";

const BidRepository = Repository.get("bid");

export default {
  components: {
    BModal,
    BRow,
    BCol,

    NFormConfirmation,
    NInput,
    NButtonLoading,
  },
  watch: {
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      key: 1,
      isShow: false,
      loading: false,
      data: {},
    };
  },
  methods: {
    show(item) {
      this.data = {
        id: item.id,
        remark: null,
      };
      this.key++;
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    success() {
      this.$emit("success");
      this.hide();
    },
    submit() {
      this.$refs.updateForm.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    save() {
      this.loading = true;
      BidRepository.update(this.data.id, {
        status: 1,
        remark: this.data.remark,
      })
        .then((response) => {
          this.success();
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>