export default [
  // {
  //   key: 'dealerId',
  //   label: 'field.dealer',
  //   rules: 'required',
  //   type: 'single-selection',
  //   repository: 'schedule',
  //   selectionKey: 'id',
  //   selectionLabel: 'name',
  //   cols: 12,
  // },
  {
    key: 'dealerName',
    label: 'field.dealer',
    rules: 'required',
    type: 'text',
    disabled: true,
    cols: 12,
  },
  {
    key: 'finalPrice',
    label: 'field.finalPrice',
    rules: 'required|decimal:2',
    type: 'currency',
    prefix: '$',
    cols: 12,
  },
  {
    key: 'remark',
    label: 'field.remark',
    rules: 'required',
    type: 'textarea',
    cols: 12,
  },
]
