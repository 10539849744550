<template>
  <div>
    <load-profile></load-profile>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card>
          <b-skeleton class="mb-2" :width="`${randomNumber()}%`"></b-skeleton>

          <b-skeleton-table
            hide-header
            :columns="6"
            :rows="3"
            borderless
            striped
          >
          </b-skeleton-table>
        </b-card>
      </template>
      <b-card v-if="data.id">
        <b-row align-v="start" class="mb-1">
          <b-col cols="12">
            <h4 class="mb-0">
              {{ data.bidDate | formatDate("DD-MMMM-YYYY") }}
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div class="d-none d-md-block">
              <b-table-simple
                responsive
                borderless
                striped
                class="table-information mb-0"
              >
                <b-tbody>
                  <b-tr>
                    <b-th> {{ $t("field.startTime") }}: </b-th>
                    <b-td> {{ data.startDate | formatDate("LT") }} </b-td>
                    <b-th> {{ $t("field.endTime") }}: </b-th>
                    <b-td> {{ data.endDate | formatDate("LT") }} </b-td>
                    <b-th> {{ $t("field.status") }}: </b-th>
                    <b-td>
                      <span
                        :class="{
                          'text-warning': data.status == 0,
                          'text-primary': data.status == 1 || data.status == 2,
                          'text-success': data.status == 3,
                          'text-danger': data.status == 4,
                        }"
                      >
                        {{ $t(`schedule.${data.status}`) }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th> {{ $t("field.vehicleQuantity") }}: </b-th>
                    <b-td>
                      {{ data.vehicleCount }}
                    </b-td>
                    <b-th> {{ $t("field.totalDealer") }}: </b-th>
                    <b-td> {{ data.bidderCount }} </b-td>
                    <b-th> {{ $t("field.totalBid") }}: </b-th>
                    <b-td> {{ data.bidCount }} </b-td>
                  </b-tr>
                  <b-tr v-if="data.status != scheduleState.RUNNING">
                    <b-th> {{ $t("field.vehicleBidded") }}: </b-th>
                    <b-td> {{ data.vehicleBidCount }} </b-td>
                    <b-th> {{ $t("field.rejected") }}: </b-th>
                    <b-td> {{ data.rejectBidCount }} </b-td>
                    <b-th> {{ $t("field.accepted") }}: </b-th>
                    <b-td> {{ data.acceptBidCount }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
            <div class="d-md-none">
              <b-table-simple
                responsive
                borderless
                striped
                class="table-information mb-0"
              >
                <b-tbody>
                  <b-tr>
                    <b-th> {{ $t("field.startTime") }}: </b-th>
                    <b-td> {{ data.startDate | formatDate("LT") }} </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th> {{ $t("field.endTime") }}: </b-th>
                    <b-td> {{ data.endDate | formatDate("LT") }} </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th> {{ $t("field.status") }}: </b-th>
                    <b-td>
                      <span
                        :class="{
                          'text-warning': data.status == 0,
                          'text-primary': data.status == 1,
                          'text-primary': data.status == 2,
                          'text-success': data.status == 3,
                          'text-danger': data.status == 4,
                        }"
                      >
                        {{ $t(`schedule.${data.status}`) }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th> {{ $t("field.vehicleQuantity") }}: </b-th>
                    <b-td>
                      {{ data.vehicleCount }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th> {{ $t("field.totalDealer") }}: </b-th>
                    <b-td> {{ data.bidderCount }} </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th> {{ $t("field.totalBid") }}: </b-th>
                    <b-td> {{ data.bidCount }} </b-td>
                  </b-tr>
                  <b-tr v-if="data.status != scheduleState.RUNNING">
                    <b-th> {{ $t("field.vehicleBidded") }}: </b-th>
                    <b-td> {{ data.vehicleBidCount }} </b-td>
                  </b-tr>
                  <b-tr v-if="data.status != scheduleState.RUNNING">
                    <b-th> {{ $t("field.rejected") }}: </b-th>
                    <b-td> {{ data.rejectBidCount }} </b-td>
                  </b-tr>
                  <b-tr v-if="data.status != scheduleState.RUNNING">
                    <b-th> {{ $t("field.accepted") }}: </b-th>
                    <b-td> {{ data.acceptBidCount }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <view-vehicle-table :schedule="data" v-if="data.id" />
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonTable,
  BButton,
} from "bootstrap-vue";
import ViewVehicleTable from "./view/ViewVehicleTable";
import Repository from "@/repositories/RepositoryFactory";
import { randomNumber } from "@/libs/helper";
import Ripple from "vue-ripple-directive";

const AuctionScheduleRepository = Repository.get("auctionSchedule");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonTable,
    BButton,

    ViewVehicleTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      data: {},
      randomNumber,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      AuctionScheduleRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = {
              ...data,
            };
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const resource = "auction-schedule";
    const route = "auction-schedule";

    return { resource, route };
  },
};
</script>