import { render, staticRenderFns } from "./AcceptModal.vue?vue&type=template&id=6889614d&"
import script from "./AcceptModal.vue?vue&type=script&lang=js&"
export * from "./AcceptModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports